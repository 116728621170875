import React from 'react';

class ScrollToButton extends React.Component {
  scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" }); // Scroll to the top of the element
    }
  }

  render() {
    const { targetId, message, classname} = this.props; // Get the targetId from props
    return (
        <button className={classname} onClick={() => this.scrollToElement(targetId)}>{message}</button>
    );
  }
}

export default ScrollToButton;
