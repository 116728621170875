import { useEffect, useRef, useState } from 'react';

const UseVisibility = (onVisible) => {
    const ref = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isVisible) {
                    setIsVisible(true);
                    onVisible();
                }
            },
            { threshold: 0.1 }  // Adjust threshold as needed
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [isVisible, onVisible]);

    return ref;
};

export default UseVisibility;
