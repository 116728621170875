import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import YAML from 'js-yaml';
import MessageBanner from '../Home/MessageBanner';


import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';

const FORM_ENDPOINT = process.env.REACT_APP_FORM_SUBMISSION_ENDPOINT_PROD;

const InterviewForm = ({data,formRef}) => {
  
  const componentName = 'Eval_InterviewForm';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);
  
  const [formData, setFormData] = useState({
    organizationName: '',
    organizationEmail: '',
    assessmentType: 'Image Classification',
    maxModelSize: '10MB',
    startDate: '',
    endDate: '',
    applicantEmails: [],
    image: null,
  });
  const [file, setFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  // const [data, setdata] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');

  useEffect(() => {
    // fetch(`${process.env.PUBLIC_URL}/website_eval_template.yaml`)
    //   .then(response => response.text())
    //   .then(yamlText => {
    //     const parsedData = YAML.load(yamlText);
    //     setdata(parsedData);
    //   })
    //   .catch(error => {
    //     console.error('Error loading YAML file:', error);
    //   });

    if (successMessage || errorMessage || warningMessage) {
      // window.scrollTo(0, 0);
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
        setWarningMessage('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage, warningMessage]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        const csv = event.target.result;
        const lines = csv.split('\n');
        const emails = lines.flatMap(line => line.split(',').filter(cell => cell.includes('@')));
        setFormData({ ...formData, applicantEmails: emails });
      };
      reader.readAsText(file);
    }
  };

  const handleImageUpload = (e) => {
    const image = e.target.files[0];
    if (image) {
      setImageFile(image);
      setFormData({ ...formData, image });
    }
  };

  const validateForm = () => {
    const errors = [];
    if (!formData.organizationName.trim()) errors.push("Organization name is required");
    if (!formData.organizationEmail.trim()) errors.push("Organization email is required");
    if (!formData.startDate) errors.push("Start date is required");
    if (!formData.endDate) errors.push("End date is required");
    if (formData.applicantEmails.length === 0) errors.push("At least one applicant email is required");
    // if (!formData.image) errors.push("An image is required");
    console.log(errors)
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      setErrorMessage(validationErrors.join(" | "));
      return;
    }

    const formatDate = (date) => {
      return new Date(date).toISOString().split('.')[0] + '.' + 
             String(new Date(date).getMilliseconds()).padStart(6, '0');
    };
    
    const jsonData = {
      host_email: formData.organizationEmail,
      host_organization: formData.organizationName,
      modality: "image",
      task: "classification",
      difficulty: "expert",
      interview_start_date: formatDate(formData.startDate),
      interview_end_date: formatDate(formData.endDate),
      model_size_restriction_MB: parseInt(formData.maxModelSize),
      interview_name: `${formData.organizationName} Assessment`,
      interview_description: "ML Assessment",
      applicant_emails: formData.applicantEmails,
      interview_id: null,
      exercise_data_id: null,
      applicant_invites_sent: false,
      host_report_path: null,
      host_report_sent: false,
      applicant_reports_paths: null,
      applicant_reports_sent: false
    };

    const formDataToSend = new FormData();
      formDataToSend.append('file', file);
      formDataToSend.append('image', imageFile);
      Object.keys(jsonData).forEach(key => {
        formDataToSend.append(key, jsonData[key]);
    });

    console.log(JSON.stringify(jsonData, null, 2));
    try {
      const response = await fetch(FORM_ENDPOINT, {
        method: 'POST',
        body: formDataToSend,
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();
      if (result.warning) {
        setWarningMessage(result.warning);
      } else {
        setWarningMessage('');
        console.log('Assessment Submitted Successfully:', result);
        setSuccessMessage('Assessment Submitted Successfully!');
        setErrorMessage('');
        
        // wait and send validation email
        // await sendValidationEmail(jsonData);
      } 
    } catch (error) {
      console.error('Error Submitting Assessment:', error);
      setErrorMessage('Failed to submit assessment. Please try again later.');
      setSuccessMessage('');
    }
  };

  // const sendValidationEmail = async (formData) => {
  //   try {
  //     const response = await fetch('http://127.0.0.1:5000/send-validation-email', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         email: formData.host_email,
  //         formData: formData,
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to send validation email');
  //     }

  //     console.log('Validation email sent successfully');
  //   } catch (error) {
  //     console.error('Error sending validation email:', error);
  //   }
  // };

  if (!data) {
    return <div>Loading...</div>;
  }

  const { launch } = data;


  const handleButtonClick = (buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Click' });
  };

  return (
    <Container fluid className="page-container home-header-section" ref={formRef}>
      <Container ref={ref} fluid className="interview-form">
        {/* <h1 className="text-center"><b>{launch?.title ?? ''}</b></h1>
        <br /> */}
        <h1 className="text-center">
          <b>{launch?.title ?? ''}</b>
        </h1>
        <h1 className='text-center' style={{ color: '#bd95ff' }}>
          <b>{launch?.title_purple ?? ''}</b>
        </h1>


        <h4 className="subscription-subtitle">{launch?.subtitle ?? ''}</h4>

        <br />   
        <br />  
        <Container className="form-container">


          <br />   
          <h6 className="subscription-subtitle" style={{'width':'75%'}}>{launch?.instructions ?? ''}</h6>     
          <br />  


          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-3" style={{ marginBottom: '0.5rem' }}>
              <Col sm={6} className="mx-auto" style={{ padding: '0.5rem' }}>
                {/* <Form.Label>Organization Name</Form.Label> */}
                <Form.Control
                  type="text"
                  name="organizationName"
                  placeholder={data?.launch?.form?.org_name ?? ''}
                  onChange={handleInputChange}
                  value={formData.organizationName}
                />
              </Col>
              <Col sm={6} className="mx-auto" style={{ padding: '0.5rem' }}>
                {/* <Form.Label>Organization Email</Form.Label> */}
                <Form.Control
                  type="email"
                  name="organizationEmail"
                  placeholder={data?.launch?.form?.org_email ?? ''}
                  onChange={handleInputChange}
                  value={formData.organizationEmail}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" style={{ marginBottom: '0.5rem' }}>
              <Col sm={6} className="mx-auto" style={{ padding: '0.5rem' }}>
                <Form.Label>{data?.launch?.form?.assessment_type_label ?? ''}</Form.Label>
                <Form.Select
                  name="assessmentType"
                  onChange={handleInputChange}
                  defaultValue="Image Classification"
                >
                  {/* <option value="Image Classification">Image Classification</option> */}
                  {data?.launch?.form?.assessment_type?.map((type, index) => (
                    <option key={index} value={type?.name ?? ''}>{type?.name ?? ''}</option>
                  ))}


                </Form.Select>
              </Col>
              <Col sm={6} className="mx-auto" style={{ padding: '0.5rem' }}>
                <Form.Label>{data?.launch?.form?.model_size_restrictions_label ?? ''}</Form.Label>
                <Form.Select
                  name="maxModelSize"
                  onChange={handleInputChange}
                  defaultValue="10MB"
                >
                  {data?.launch?.form?.model_size_restrictions?.map((restriction, index) => (
                    <option key={index} value={restriction?.value ?? ''}>{restriction?.name ?? ''}</option>
                  ))}

                </Form.Select>
              </Col>

            </Form.Group>

            <Form.Group as={Row} className="mb-3" style={{ marginBottom: '0.5rem' }}>
              <Col sm={6} className="mx-auto" style={{ padding: '0.5rem' }}>
                <Form.Label>{data?.launch?.form?.start_date ?? ''}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="startDate"
                  onChange={handleInputChange}
                  value={formData.startDate}
                />
              </Col>
              <Col sm={6} className="mx-auto" style={{ padding: '0.5rem' }}>
                <Form.Label>{data?.launch?.form?.end_date ?? ''}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="endDate"
                  onChange={handleInputChange}
                  value={formData.endDate}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" style={{ marginBottom: '0.5rem' }}>
              <Col sm={6} className="mx-auto" style={{ padding: '0.5rem' }}>
                <Form.Label>{data?.launch?.form?.csv_upload ?? ''}</Form.Label>
                <Form.Control
                  type="file"
                  accept=".csv"
                  onChange={handleFileUpload}
                />
              </Col>
              <Col sm={6} className="mx-auto" style={{ padding: '0.5rem' }}>
                <Form.Label>{data?.launch?.form?.logo_upload ?? ''}</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </Col>
            </Form.Group>

            <Button type="submit" variant="primarypurple" className="launch-button" onClick={() => handleButtonClick(data?.launch?.form?.submit_button ?? '')}>{data?.launch?.form?.submit_button ?? ''}</Button>
          </Form>
          <br></br>
          <MessageBanner message={successMessage} type="success" />
          <MessageBanner message={errorMessage} type="error" />
          <MessageBanner message={warningMessage} type="warning" />

        </Container>
      </Container> 
    </Container>
  );
};

export default InterviewForm;
