import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ScrollToButton from "./ScrollToButton";


import { IoSpeedometer } from "react-icons/io5";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircle } from '@fortawesome/free-solid-svg-icons';


// import { FontAwesomeIcon } from './react-fa';
// import { faBullseyeArrow } from '@fortawesome/free-solid-svg-icons';

// import { FaBullseye } from "react-icons/fa";

// import { FaCircle } from 'react-icons/fa';
// import { GoLightBulb } from "react-icons/go";
import { CiWarning } from "react-icons/ci";


import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';


function InfoRow0({problem_1_data,problem_2_data,problem_3_data}) {

  const componentName = 'InfoRow0';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);


  return (
    <Row ref={ref} className='info-row'>
      {/* <Col md={3} className='info-container'>
        <div className='info-productivity-div'>

            <h1 className='info-div-title' >
             Until now, ML R&D has been ...
            </h1>

        </div>

      </Col> */}


      <Col md={3} className='info-container'>
       

        <div className='info-goal-div'>

            <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/connect_icon.svg'} style={{ height: '50%' , margin: "auto",display: "block",}}></object>

            <div className='info-div-button' >
                
                <h2 >
                  {problem_1_data?.title ?? ''}
                </h2>
                <h6 >
                  {problem_1_data?.description ?? ''}
                </h6>
            </div>
        </div>
      </Col>


      <Col md={3} className='info-container'>
       

        <div className='info-goal-div'>

            <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/benchmark_icon.svg'} style={{ height: '50%' , margin: "auto",display: "block",}}></object>

            <div className='info-div-button' >
                
                <h2>
                  {problem_2_data?.title ?? ''}
                </h2>
                <h6>
                  {problem_2_data?.description ?? ''}
                </h6>
            </div>
        </div>
      </Col>


      <Col md={3} className='info-container'>
       

       <div className='info-goal-div'>

           <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/progress_icon.svg'} style={{ height: '50%' , margin: "auto",display: "block",}}></object>

           <div className='info-div-button' >
               
                <h2 >
                  {problem_3_data?.title ?? ''}
                </h2>
                <h6 >
                  {problem_3_data?.description ?? ''}
                </h6>

           </div>
       </div>
     </Col>

    </Row>

  );
}



export default InfoRow0;
// export InfoRow2;
