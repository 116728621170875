import React, { useState } from 'react';
import SendEvent from '../SendEvent';

const ToggleText = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const texts = [
    { name: data?.build?.testimonial?.student?.name ?? '', message: data?.build?.testimonial?.student?.message ?? '' },
    { name: data?.build?.testimonial?.researcher?.name ?? '', message: data?.build?.testimonial?.researcher?.message ?? '' },
    { name: data?.build?.testimonial?.engineer?.name ?? '', message: data?.build?.testimonial?.engineer?.message ?? '' },
    { name: data?.build?.testimonial?.leader?.name ?? '', message: data?.build?.testimonial?.leader?.message ?? '' },
    { name: data?.build?.testimonial?.university?.name ?? '', message: data?.build?.testimonial?.university?.message ?? '' }
  ];

  const componentName = 'ToggleText';

  const handleClick = (index,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    setActiveIndex(index);
  };

  return (
    <div>
        <br></br>

        <div className='info-div-button-top' >

                <h6>
                    {data?.build?.testimonial?.label ?? ''}
                </h6>
        </div>
        <br></br>
        <div className="top-button-group build-button-group">

            {texts.map((text, index) => (
            <button
                key={index}
                className={`top-button-style ${index === activeIndex ? 'active' : ''}`}
              onClick={() => handleClick(index,text.name)}
            >
                {text.name}
            </button>
            ))}
        </div>
        <br></br>
        <br></br>
        <div className='info-div-button-top' >

            <div className="text-display">
                <div>
                {/* Render only the text content of the active index */}
                <div className="text-container">
                    <h4>{texts[activeIndex].message}</h4>
                </div>
                </div>
            </div>


        </div>

      <br></br>
      <br></br>
    </div>
  );
};

export default ToggleText;
