import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";

import org_work from "../../Assets/org_work.png";

import mf_logo from "../../Assets/mf_logo_white.png";
import ripa_logo from "../../Assets/ripa_logo_white.png";
import vamos_logo from "../../Assets/vamos_logo_white.png";

import org_support from "../../Assets/org_support.png";

import techstars_logo from "../../Assets/techstars_logo_white.png";
import nvidia_logo from "../../Assets/nvidia_logo_white.png";
import aws_logo from "../../Assets/aws_logo_white.png";
import alo_logo from "../../Assets/alo_logo_white.png";
import nbt_logo from "../../Assets/nbt_white_logo.png"


function Slider() {
  return (
    
    <Container fluid className="home-slider" id="home-slider">

      <div className="slider">
        <div className="slide-track2">

          <div className="slide"><img src={org_work} style={{width:"100%"}}/></div>
          <div className="slide"><img src={mf_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={ripa_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={vamos_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={alo_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={nbt_logo} style={{width:"100%"}}/></div>

          <div className="slide"><img src={org_support} style={{width:"100%"}}/></div>
          <div className="slide"><img src={techstars_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={nvidia_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={aws_logo} style={{width:"100%"}}/></div>

          <div className="slide"><img src={org_work} style={{width:"100%"}}/></div>
          <div className="slide"><img src={mf_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={ripa_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={vamos_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={alo_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={nbt_logo} style={{width:"100%"}}/></div>

          <div className="slide"><img src={org_support} style={{width:"100%"}}/></div>
          <div className="slide"><img src={techstars_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={nvidia_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={aws_logo} style={{width:"100%"}}/></div>

          <div className="slide"><img src={org_work} style={{width:"100%"}}/></div>
          <div className="slide"><img src={mf_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={ripa_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={vamos_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={alo_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={nbt_logo} style={{width:"100%"}}/></div>

          <div className="slide"><img src={org_support} style={{width:"100%"}}/></div>
          <div className="slide"><img src={techstars_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={nvidia_logo} style={{width:"100%"}}/></div>
          <div className="slide"><img src={aws_logo} style={{width:"100%"}}/></div>

        </div>
      </div>

    </Container>
  );
}
export default Slider;
