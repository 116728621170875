import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import TopButtonGroup from "./TopButtonGroup";

import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';



function Header({data,header_data}) {

  const componentName = 'Header';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);

  const handleClick = (url,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    if (url) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    
    <Container ref={ref} fluid className="home-header-section" id="home-header">
        
        <br></br>
        <br></br>
        <br></br>


        
        <Container fluid className="home-header-description" id="home-header">



          {/* <h1 style={{ maxWidth: '500px' , margin: '0 auto'}}>
          {data?.header?.body ?? ''}
          </h1> */}


          <TopButtonGroup
             leftButtonText={data?.header?.top_button_group?.left_button_text ?? ''}
             centerText={data?.header?.top_button_group?.center_text ?? ''}
             rightButtonText={data?.header?.top_button_group?.right_button_text ?? ''}
             leftButtonUrl={data?.header?.top_button_group?.left_button_url ?? ''}
             rightButtonUrl={data?.header?.top_button_group?.right_button_url ?? ''}
          />
          <br></br>


          <h1 className="title">
            <b>{data?.header?.title ?? ''}</b>
          </h1>
          <h1 className='title' style={{ color: '#bd95ff' }}>
            <b>{data?.header?.subtitle ?? ''}</b>
          </h1>
          <h5 className="subscription-subtitle">
            {/* You can use Modlee to reduce your experimentation and deploy ML solutions faster in three easy steps */}
            {data?.header?.body ?? ''}
          </h5>


        </Container>

        {/* <Button variant="primarypurple" href={header_data?.url ?? ''} target="_blank" rel="noopener noreferrer">
          {header_data?.title ?? ''}
        </Button> */}

        <Button 
            variant="primarypurple" 
            onClick={() => handleClick(header_data?.url ?? '', header_data?.title ?? '')}>
            {header_data?.title ?? ''}
        </Button>



        <br></br>
        <br></br>

    </Container>
  );
}
export default Header;
