import React, { useState, useEffect } from "react";
import Preloader from "./components/Pre";
import HomeToggle from "./components/Home/HomeToggle";
import Eval from "./components/Eval/Eval";

import Footer from "./components/Footer";
import InterviewForm from './components/Eval/InterviewForm';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import "./App.css";
import "./slider.css";

import TermsAndConditionsPage from "./components/Home/TermsConditionsPage";
import DataPrivacyPolicyPage from "./components/Home/DataStatementPage";
import OpenSourceDisclaimerPage from "./components/Home/OpenSourceDisclaimerPage";

import YAML from 'js-yaml';

function App() {


  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);


  const [dataHome, setDataHome] = useState(null);
  const [dataEval, setDataEval] = useState(null);

  useEffect(() => {
    // Load first YAML file
    fetch(`${process.env.PUBLIC_URL}/website_template.yaml`)
      .then(response => response.text())
      .then(yamlData => {
        const parsedData = YAML.load(yamlData);
        setDataHome(parsedData);
      })
      .catch(error => {
        console.error('Error loading Data Home YAML file:', error);
      });

    // Load second YAML file
    fetch(`${process.env.PUBLIC_URL}/website_eval_template.yaml`)
      .then(response => response.text())
      .then(yamlData => {
        const parsedData = YAML.load(yamlData);
        setDataEval(parsedData);
      })
      .catch(error => {
        console.error('Error loading Data Eval YAML file:', error);
      });
  }, []);
  

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomeToggle data={dataHome}/>} />
          <Route path="*" element={<Navigate to="/"/>} />
          <Route path="/terms" element={<TermsAndConditionsPage/>} />
          <Route path="/data_policy" element={<DataPrivacyPolicyPage/>} />
          <Route path="/open_source_disclaimer" element={<OpenSourceDisclaimerPage/>} />
          <Route path="/eval" element={<Eval data={dataEval}/>} />
        </Routes>
        <Footer data={dataHome}/>
      </div>
    </Router>
  );
}

export default App;
