const dataStatement = `

Data and Privacy Statement
Apr 1, 2024

Table of Contents

Data and Privacy Statement	2
An Overview of Modlee Services	2
Information we use to make and improve model recommendations	2
User Dataset	2
User Dataset Analysis	3
Contacting Us	3


Data and Privacy Statement
This Privacy Statement explains our practices, including your choices, regarding the collection, use, and disclosure of certain information, including personal information in connection with the Modlee service.


An Overview of Modlee Services



Information we use to make and improve model recommendations
User Dataset
User-owned data, encompassing text, images, audio, etc., is meticulously organized and annotated to align with specific machine learning tasks such as classification, regression, and generation.

Crucially, User Datasets remain exclusively within the environment where the User Codebase is executed and are never transmitted beyond this secure environment.
User Dataset Analysis
The Modlee python package conducts a thorough analysis of user datasets, generating a set of non-reversible meta features that inherently prevent the reconstruction of the user's dataset. Examples of these non-reversible meta features include aggregated statistics like mean, standard deviation, variance, maximum, and minimum values, as well as considerations such as the number of data elements, size, or shape of each data element. Meta features are also extracted from data annotations in a similar privacy-preserving manner.

It is important to note that User Dataset Analysis is transmitted to our Modlee Server exclusively when a user employs Modlee’s python package to obtain model recommendations for their dataset. 

Certain information pertaining to Users experiments is automatically retained. This includes User Dataset Analysis, the Modlee Recommended model, and Performance metrics post-training.

It is important to highlight that the storage of this information is solely for the purpose of refining Modlee’s system. This iterative process is geared towards enhancing the accuracy and effectiveness of model recommendations for User Datasets, leveraging insights derived from Modlee’s User Dataset Analysis.

When you visit or log in to our website, cookies  and similar technologies may be used by our online data partners or vendors to associate these activities with other personal information they or others have about you, including by association with your email or home address. We (or service providers on our behalf) may then send communications and marketing to these email or home addresses. You may opt out of receiving this advertising by visiting https://app.retention.com/optout


Contacting Us
If you have general questions or need assistance, please reach out to us at support@modlee.ai. 
`;

export default dataStatement;
