import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// import { FontAwesomeIcon } from './react-fa';
// import { faBullseyeArrow } from '@fortawesome/free-solid-svg-icons';

// import { FaBullseye } from "react-icons/fa";

import { SiPytorch } from "react-icons/si";

import { GiBullseye } from "react-icons/gi";
import { FaPython} from "react-icons/fa";

import { FaConnectdevelop } from "react-icons/fa";
import { GiParkBench } from "react-icons/gi";
import { SiWpexplorer } from "react-icons/si";


import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';

function Feature0({data,feature_2_data}) {

  const componentName = 'Feature0';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);

  const handleClick = (url,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    if (url) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
  };


  return (
    <Row ref={ref} className='info-row'>

        <Col md={5} className='feature-container-text' >
            <div className='feature-header-div'>

            {/* <h4 className='feature-step' >
              <b>2. Benchmark with ...</b>
            </h4>

            <h2 className='feature-title' >
              <b>ML model architecture recommendations</b>
            </h2>

            <h5 className='feature-text' >
            
            Modlee derives insights from your group's experiments to create a personalized model architecture recommendation for your dataset. As your dataset evolves, simply re-run your Modlee pipeline to uncover improved models your group has found. Say goodbye to scouring Hugging Face, Kaggle, Papers with Code, and colleagues' code for experiment inspiration. Modlee's package works in any data and computing environment where Python and PyTorch can be used.

            </h5>

            <br></br>

            <button className='feature-link' onClick={() => window.location.href='https://www.documentation.modlee.ai/notebooks/recommend.html'} >Learn More > </button> */}

{/* 
            <h4 className='feature-step' >
              <b>{data?.features?.feature_2?.section_label ?? ''}</b>
            </h4> */}

            <h2 className='feature-title' >
              <b>{data?.features?.feature_2?.title ?? ''}</b>
            </h2>

            <h5 className='feature-text' >
            
              {feature_2_data?.body ?? ''}
            </h5>
            <br></br>

            {/* <a className='info-bottom-link' href={data?.features?.feature_2?.button_url ?? ''}  target="_blank" rel="noopener noreferrer">
              {data?.features?.feature_2?.button_title ?? ''}
            </a> */}

            <a 
              className='info-bottom-link'
              onClick={() => handleClick(data?.features?.feature_2?.button_url ?? '', data?.features?.feature_2?.button_title ?? '')}>
              {data?.features?.feature_2?.button_title ?? ''}
            </a>

            </div>
        </Col>


        {/* Learn More >
Modal: Using Modlee benefits both you and the community. We aggregate and study details of community shared experiments to recommend the best community derived ML architectures for new datasets and certain tasks. We’re creating a new way to collaborate together, while protecting the core of your IP: your datasets and trained models. Check out our public repo to learn more about how we analyze your dataset to make recommendations, and what we share about Modlee Purple experiments to improve model recommendations for all. Read our data privacy statement to learn more about how we handle your data for self improvement. */}



      <Col md={5} className='feature-container-diagram' >
        {/* <div className='feature-graphic-div'> */}

            <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/rec.svg'} ></object>


            {/* Consider changing graphic to not be graph print out only, also have neural network with no fill for architecture and neural network with fill for trained model */}


        {/* </div> */}

      </Col>

    </Row>

  );
}



export default Feature0;
// export InfoRow2;
