import Accordion from 'react-bootstrap/Accordion';
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "../../style.css";

import React, { useState, useEffect} from 'react';

import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';



const Question = ({ question, answer ,index}) => {

  const handleAccordionClick = (question) => {
      // Send event data when an accordion item is clicked
      SendEvent('click', 'Eval_AccordionItem', { question });
  };

  return (
      <Accordion.Item eventKey={index}>
        {/* <Accordion.Header>{question}</Accordion.Header> */}
        <Accordion.Header onClick={() => handleAccordionClick(question)}>
          {question}</Accordion.Header>

        <Accordion.Body style={{ textAlign: 'left' }}>
        {answer}
        </Accordion.Body>
      </Accordion.Item>
  );
};

function NestedAccordion({data}) {

  const [showTC, setTCShow] = useState(false);
  const [showDS, seDSShow] = useState(false);

  const handleTCClose = () => setTCShow(false);
  const handleTCShow = () => setTCShow(true);
  const handleDSClose = () => seDSShow(false);
  const handleDSShow = () => seDSShow(true);

  const componentName = 'Eval_FAQ';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);


  return (

    <Container >
      <Container>


      <div ref={ref} className="info-header-container">

        <h1 style={{color:'white'}}>
          <b>{data?.faq?.title ?? ''}</b>
        </h1>

        <h5 className="subscription-subtitle">
          {/* Whether it’s AI startup products, research papers, or hobby & school projects, developers all over the world use Modlee to collaborate together unlocking their ability to be more productive & build better solutions */}
          {data?.faq?.body ?? ''}
        </h5>


      </div>

      {/* <Button variant="primarypurple" href={data?.faq?.call_to_action.button_url ?? ''} target="_blank" rel="noopener noreferrer">
        {data?.faq?.call_to_action.button_title ?? ''}
      </Button> */}

      {/* <br></br>
      <br></br>
      <br></br>
      <br></br> */}

    <Accordion className='react-bootstrap-accordion'>

      {data?.faq?.questions?.map((question, index) => (
        <Question 
          key={index}
          question={question?.question ?? ''}
          answer={question?.answer ?? ''}
          index={index}
        />
      ))}


      <Accordion.Item eventKey="500">
        <Accordion.Header>More questions ...</Accordion.Header>
        <Accordion.Body>


          <Accordion className='react-bootstrap-accordion'>


            {data?.faq?.more_questions?.map((question, index) => (
              <Question 
                key={index}
                question={question?.question ?? ''}
                answer={question?.answer ?? ''}
                index={index}
              />
            ))}

          </Accordion>

        </Accordion.Body>
      </Accordion.Item>



    </Accordion>



    <br/>

        


</Container>
</Container>

  );
}

export default NestedAccordion;

// export default Faq;