import React from 'react';
import { Alert } from 'react-bootstrap';

const MessageBanner = ({ message, type }) => {
  if (!message) return null;

  const variantMap = {
    success: 'success',
    error: 'danger',
    warning: 'warning'
  };

  return (
    <Alert variant={variantMap[type] || 'info'} className="text-center">
      {message}
    </Alert>
  );
};


export default MessageBanner;