// const termsContent = `
// Coming Soon
// `;

// export default termsContent;

import React from 'react';

import openSourceDisclaimerContent from './OpenSourceDisclaimer';

// const termsContent = `
// Coming Soon
// `;

import { Container, Row, Col } from "react-bootstrap";



function OpenSourceDisclaimerPage() {
    return (

        <section>

            <Container fluid className="home-header-section">
                <Container fluid className="home-header-description">

                    <h2>Modlee's Open Source Disclaimer</h2>
                    <p>{openSourceDisclaimerContent}</p>

                </Container>
            </Container>

        </section>

    );
}

export default OpenSourceDisclaimerPage;