// import React from 'react';

// const TopButtonGroup = ({ leftButtonText, centerText, rightButtonText, leftButtonUrl, rightButtonUrl }) => {
//   // Function to handle navigation
//   const handleButtonClick = (url) => {
//     window.open(url, "_blank"); // Opens the URL in a new tab
//   }

//   return (
//     <div className="top-button-group">
//       <button className="top-button-style" onClick={() => handleButtonClick(leftButtonUrl)}>
//         {leftButtonText}
//       </button>
//       <span className="top-text-style">{centerText}</span>
//       <button className="top-button-style" onClick={() => handleButtonClick(rightButtonUrl)}>
//         {rightButtonText}
//       </button>
//     </div>
//   );
// }

// export default TopButtonGroup;


import React from 'react';
import SendEvent from '../SendEvent';

const TopButtonGroup = ({ leftButtonText, centerText, rightButtonText, leftButtonUrl, rightButtonUrl }) => {
  // Function to handle navigation or download
  const componentName = 'TopButtonGroup';
  const handleButtonClick = (url,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    if (url.endsWith('.pdf')) {
      // Create an invisible link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = url.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // Opens the URL in a new tab
      window.open(url, "_blank");
    }
  }

  return (
    <div className="top-button-group">
      <button className="top-button-style" onClick={() => handleButtonClick(leftButtonUrl,leftButtonText)}>
        {leftButtonText}
      </button>
      <span className="top-text-style">{centerText}</span>
      <button className="top-button-style" onClick={() => handleButtonClick(rightButtonUrl,rightButtonText)}>
        {rightButtonText}
      </button>
    </div>
  );
}

export default TopButtonGroup;
