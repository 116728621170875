import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// import { FontAwesomeIcon } from './react-fa';
// import { faBullseyeArrow } from '@fortawesome/free-solid-svg-icons';

// import { FaBullseye } from "react-icons/fa";


import { GiBullseye } from "react-icons/gi";

import { FaConnectdevelop } from "react-icons/fa";
import { GiParkBench } from "react-icons/gi";
import { SiWpexplorer } from "react-icons/si";


import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';


function Feature2({data,feature_1_data}) {

  const componentName = 'Feature2';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);


  return (
    <Row ref={ref} className='info-row'>

        <Col md={5} className='feature-container-text' >
            <div className='feature-header-div'>

            {/* <h4 className='feature-step' >
              <b>{data?.features?.feature_1?.section_label ?? ''}</b>
            </h4> */}

            <h2 className='feature-title' >
              <b>{data?.features?.feature_1?.title ?? ''}</b>
            </h2>

            <h5 className='feature-text' >
            
              {feature_1_data?.body ?? ''}
            </h5>
            <br></br>

            {/* <a className='info-bottom-link' href={data?.features?.feature_1?.button_url ?? ''}  target="_blank" rel="noopener noreferrer">
              {data?.features?.feature_1?.button_title ?? ''}
            </a> */}

            </div>
        </Col>


      <Col md={5} className='feature-container-diagram' >
        {/* <div className='feature-graphic-div'> */}

            <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/collab.svg'} ></object>
            {/* <object type="image/svg+xml" data={process.env.PUBLIC_URL + data?.features?.feature_1?.button_url ?? ''} style={{ height: '100%' }}></object> */}

        {/* </div> */}

      </Col>

    </Row>

  );
}



export default Feature2;
// export InfoRow2;
