
// import React from 'react';
// import SendEvent from './SendEvent';

// const TopButtonGroup = ({ leftButtonText, centerText, rightButtonText, leftButtonUrl, rightButtonUrl }) => {
//   // Function to handle navigation or download

//   const componentName = 'TopButtonGroup';
//   // const handleButtonClick = (url,buttonName) => {
//   //   SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
//   //   if (url.endsWith('.pdf')) {
//   //     // Create an invisible link element to trigger the download
//   //     const link = document.createElement('a');
//   //     link.href = url;
//   //     link.download = url.split('/').pop();
//   //     document.body.appendChild(link);
//   //     link.click();
//   //     document.body.removeChild(link);

//   //   } else {
//   //     // Assume url is a ref object
//   //     url.current.scrollIntoView({ behavior: 'smooth' });
//   //   }
//   // };
  
//   const handleButtonClick = (url) => {
//     SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
//     if (typeof url === 'string') {
//       if (url.endsWith('.pdf')) {
//         // Create an invisible link element to trigger the download
//         const link = document.createElement('a');
//         link.href = url;
//         link.download = url.split('/').pop();
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       } else if (url.startsWith('http')) {
//         window.open(url, "_blank");
//       }
//     } else {
//       // Assume url is a ref object
//       url.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   };
//   return (
//     <div className="top-button-group">
//       <button className="top-button-style" onClick={() => handleButtonClick(leftButtonUrl,leftButtonText)}>
//         {leftButtonText}
//       </button>
//       <span className="top-text-style">{centerText}</span>
//       <button className="top-button-style" onClick={() => handleButtonClick(rightButtonUrl,rightButtonText)}>
//         {rightButtonText}
//       </button>
//     </div>
//   );
// }

// export default TopButtonGroup;


// import React from 'react';

// const TopButtonGroup = ({ leftButtonText, centerText, rightButtonText, leftButtonUrl, rightButtonUrl }) => {
//   // Function to handle navigation
//   const handleButtonClick = (url) => {
//     window.open(url, "_blank"); // Opens the URL in a new tab
//   }

//   return (
//     <div className="top-button-group">
//       <button className="top-button-style" onClick={() => handleButtonClick(leftButtonUrl)}>
//         {leftButtonText}
//       </button>
//       <span className="top-text-style">{centerText}</span>
//       <button className="top-button-style" onClick={() => handleButtonClick(rightButtonUrl)}>
//         {rightButtonText}
//       </button>
//     </div>
//   );
// }

// export default TopButtonGroup;


import React from 'react';
import SendEvent from './SendEvent';

const TopButtonGroup = ({ leftButtonText, centerText, rightButtonText, leftButtonUrl, rightButtonUrl }) => {
  // Function to handle navigation or download
  const componentName = 'TopButtonGroup';
  const handleButtonClick = (url,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    if (typeof url === 'string') {
      if (url.endsWith('.pdf')) {
        // Create an invisible link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = url.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (url.startsWith('http')) {
        window.open(url, "_blank");
      }
    } else {
      // Assume url is a ref object
      url.current.scrollIntoView({ behavior: 'smooth' });
    }
}

  return (
    <div className="top-button-group">
      <button className="top-button-style" onClick={() => handleButtonClick(leftButtonUrl,leftButtonText)}>
        {leftButtonText}
      </button>
      <span className="top-text-style">{centerText}</span>
      <button className="top-button-style" onClick={() => handleButtonClick(rightButtonUrl,rightButtonText)}>
        {rightButtonText}
      </button>
    </div>
  );
}

export default TopButtonGroup;

