// const termsContent = `
// Coming Soon
// `;

// export default termsContent;

import React from 'react';

import termsConditions from './TermsConditions';

// const termsContent = `
// Coming Soon
// `;

import { Container, Row, Col } from "react-bootstrap";



function TermsAndConditionsPage() {
    return (

        <section>

            <Container fluid className="home-header-section">
                <Container fluid className="home-header-description">

                    <h2>Modlee's Terms of Service</h2>
                    <p>{termsConditions}</p>

                </Container>
            </Container>

        </section>

    );
}

export default TermsAndConditionsPage;