import React from 'react';
import '../../style.css'; // Import CSS file for custom styling if needed
import { Container, Row, Col } from "react-bootstrap";

import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';



function HowHeader({data}) {

  const componentName = 'Eval_HowHeader';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);

  return (

    <Container ref={ref} fluid className="home-problem-header-section" id="home-header">
        <Container fluid className="home-header-description" id="home-header">

          <div className="section_label">
            {data?.how?.label ?? ''}
          </div>

          <h1 >
             {/* <b>Until now, ML R&D has been ...</b> */}
             <b>{data?.how?.title_top ?? ''}</b>
          </h1>
          <h1 style={{ color: '#bd95ff' }}>
            <b>{data?.how?.title_bottom ?? ''}</b>
          </h1>

        </Container>

    </Container>

  );
}

export default HowHeader;
