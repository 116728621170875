import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import ToggleSVG from "./ToggleSVG";


import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';



function CodeToggle({data}) {

  const componentName = 'CodeToggle';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);


  return (
    

    
    <Container ref={ref} fluid className="home-header-section" id="home-header">
        


        
        <Container fluid className="code-div" id="code-div">

            <ToggleSVG
                button_1_text={data?.code?.button_1_text ?? ''}
                button_2_text={data?.code?.button_2_text ?? ''}
                button_3_text={data?.code?.button_3_text ?? ''}          
            />


          {/* <h1 className="title">
            <b>{data?.header?.title ?? ''}</b>
          </h1>
          <h1 className='title' style={{ color: '#bd95ff' }}>
            <b>{data?.header?.subtitle ?? ''}</b>
          </h1>
          <h5 className="subscription-subtitle">
            {data?.header?.body ?? ''}
          </h5> */}


        </Container>

    </Container>
  );
}
export default CodeToggle;
