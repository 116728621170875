import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Footer({data}) {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        {/* <Col md="4" className="footer-copywright">
          <h3>Modlee Inc</h3>
        </Col> */}
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} Modlee, Inc</h3>
        </Col>
        <Col md="4" className="footer-body">
          {/* <ul className="footer-icons">
            
            <li className="social-icons">
              <a
                // href="http://localhost:3000/terms"
                href="https://modlee.ai/terms"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </li>

            <li >
              <a
                // href="http://localhost:3000/terms"
                href="https://modlee.ai/data_policy"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                Data Policy
              </a>
            </li>

            <li >
              <a
                // href="http://localhost:3000/terms"
                href="https://modlee.ai/open_source_disclaimer"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                open_source_disclaimer
              </a>
            </li>

          </ul> */}

              <a
                // href="http://localhost:3000/terms"
                href={data?.terms_url ?? ''}
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
              {"  "}
              <a
                // href="http://localhost:3000/data_policy"
                href={data?.data_policy_url ?? ''}
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                Data Policy
              </a>
              {"  "}
              <a
                // href="http://localhost:3000/terms"
                href={data?.open_source_disclaimer ?? ''}
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                Open Source Disclaimer
              </a>


        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
