import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import TopButtonGroup from "./TopButtonGroup";


import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';


function Simplify({data,header_data}) {

  const componentName = 'Simplify';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);

  const handleClick = (url,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    if (url) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    
    <Container ref={ref} fluid className="home-header-section" id="home-header">
        
        
        <Container fluid className="home-header-description" id="home-header">



          <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/world_connection_1.svg'} style={{width: '100%'}}></object>


          <h1 className="title">
            <b>{data?.simplify?.title ?? ''}</b>
          </h1>
          <h5 className="subscription-subtitle">
            {/* You can use Modlee to reduce your experimentation and deploy ML solutions faster in three easy steps */}
            {data?.simplify?.body ?? ''}
          </h5>
          <h3 style={{ color: '#bd95ff' }}>
            <b>{data?.simplify?.subtitle ?? ''}</b>
          </h3>


        </Container>

        {/* <Button variant="primarypurple" href={data?.simplify?.button_url ?? ''} target="_blank" rel="noopener noreferrer">
          {data?.simplify?.button_text ?? ''}
        </Button> */}

        <Button 
            variant="primarypurple" 
            onClick={() => handleClick(data?.simplify?.button_url ?? '', data?.simplify?.button_text ?? '')}>
            {data?.simplify?.button_text ?? ''}
        </Button>


        <br></br>
        <br></br>

    </Container>
  );
}
export default Simplify;
