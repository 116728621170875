import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import YAML from 'js-yaml';
import MessageBanner from '../Home/MessageBanner';

const FORM_ENDPOINT = process.env.REACT_APP_TALENT_POOL_ENDPOINT_PROD;


import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';



const JoinTalentPool = ({data,joinRef}) => {
  

  const componentName = 'JoinTalentPool';
  const onVisible = () => SendEvent('visibility', componentName);
  const refVis = UseVisibility(onVisible);

  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');

  useEffect(() => {
    if (successMessage || errorMessage || warningMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
        setWarningMessage('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage, warningMessage]);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const validateForm = () => {
    const errors = [];
    if (!email.trim()) errors.push("Email address is required");
    if (!/\S+@\S+\.\S+/.test(email)) errors.push("Email address is invalid");
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      setErrorMessage(validationErrors.join(" | "));
      return;
    }

    try {
      // Replace this with your form submission endpoint
      const response = await fetch(FORM_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.warning) {
        setWarningMessage(result.warning);
      } else {
        setWarningMessage('');
        setSuccessMessage('Successfully subscribed to the talent pool!');
        setErrorMessage('');
      }
    } catch (error) {
      console.error('Error subscribing to the talent pool:', error);
      setErrorMessage('Failed to subscribe to the talent pool. Please try again.');
      setSuccessMessage('');
    }
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  const { talent } = data;

  return (
    <Container ref={joinRef} fluid className="page-container home-header-section">
        <Container ref={refVis} fluid className="home-header-description">
            <h1 className="title"><b>{talent?.title ?? ''}</b></h1>
            <br />
            {/* <h4 className="subscription-subtitle">{talent?.subtitle ?? ''}</h4> */}
            <h1 className='text-center' style={{ color: '#bd95ff' }}>
                <b>{talent?.subtitle ?? ''}</b>
            </h1>

            <br />
            <h4 className="subscription-subtitle">{talent?.subtitle_2 ?? ''}</h4>

            <br />   
            <br />  
            <Container className="form-container">
                <br />
                <h6 className="subscription-subtitle">{talent?.instructions ?? ''}</h6>     
                <br />

              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3">
                  <Col sm={6} className="mx-auto">
                  <Form.Control
                    type="email"
                    name="candidateEmail"
                    placeholder={data?.talent?.form?.candidate_email ?? ''}
                    onChange={handleInputChange}
                    value={email}
                  />
                  </Col>
                </Form.Group>
                <Button type="submit" variant="primarypurple" className="launch-button">{data?.talent?.form?.submit_button ?? ''}</Button>            
              </Form>
              <br></br>
              <MessageBanner message={successMessage} type="success" />
              <MessageBanner message={errorMessage} type="error" />
              <MessageBanner message={warningMessage} type="warning" />
            </Container>
        </Container>
    </Container>
  );
};

export default JoinTalentPool;