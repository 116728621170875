import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import '../../style.css'; // Import CSS file for custom styling if needed

function FeaturesHeader({data}) {
  return (

    <Container fluid className="home-problem-header-section" id="home-header">
        <Container fluid className="home-header-description" id="home-header">


      <div className="section_label">
        {data?.features?.label ?? ''}
      </div>
      <h1 className="info-header-title"><b>{data?.features?.title ?? ''}</b></h1>

      </Container>
  </Container>

  );
}

export default FeaturesHeader;
