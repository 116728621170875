// const dataStatement = `
// Coming Soon
// `;

// export default dataStatement;


import React from 'react';

// const dataStatement = `
// Coming Soon
// `;

import dataStatement from './DataStatement';

import { Container, Row, Col } from "react-bootstrap";

function DataPrivacyPolicyPage() {
    return (


        <section>

            <Container fluid className="home-header-section">
                <Container fluid className="home-header-description">

                    <h2>Modlee's Data Privacy Policy</h2>
                    <p>{dataStatement}</p>

                </Container>
            </Container>

        </section>


    );
}

export default DataPrivacyPolicyPage;