import React, { useRef } from 'react';
import Header from "../Eval/Header";
import Simplify from "../Eval/Simplify";
import HowHeader from "../Eval/HowHeader";
import How from "../Eval/How";
import FeaturesHeader from "../Eval/FeaturesHeader";
import Features from "../Eval/Features";
import Value from "../Eval/Value";
import Faq from "../Eval/Faq";
import InterviewForm from "../Eval/InterviewForm";

import NavbarEval from "../NavbarEval";
import JoinTalentPool from "../Eval/JoinTalentPool"

function Eval({data}) {

  const formRef = useRef(null);
  const joinRef = useRef(null);

  return (
    
    <section>

      <NavbarEval 
        data={data}
        formRef={formRef}
        joinRef={joinRef}
      />

      <Header 
        data={data}
        formRef={formRef} 
      />

      <Simplify 
        data={data}
        formRef={formRef} 
      />

      <br></br>

      <HowHeader 
        data={data} 
      />

      <How 
        data={data} 
      />

      <br></br>
      <InterviewForm 
        data={data}
        formRef={formRef}  
      />

      <FeaturesHeader 
        data={data} 
      />

      <Features 
        data={data} 
      />

        <br></br>
        <br></br>
        <br></br>


      <Value 
        data={data}
        formRef={formRef}   
      />

        <br></br>
        <br></br>
        <br></br>

      <Faq 
        data={data} 
      />

        <br></br>
        <br></br>
        <br></br>
        
      <JoinTalentPool
        data={data}
        joinRef={joinRef}
      />

        <br></br>
        <br></br>
        <br></br>

    </section>

  );
}

export default Eval;





