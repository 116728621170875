//  * Sends an event to the server.
//  * @param {string} eventType - The type of the event (e.g., 'visibility', 'click').
//  * @param {string} componentName - The name of the component where the event occurred.
//  * @param {object} additionalData - Any additional data to be sent with the event.
 
const TRACK_ENDPOINT = process.env.REACT_APP_TRACK_SUBMISSION_ENDPOINT;
const SECRET_KEY = process.env.REACT_APP_BACKEND_SECRECT_KEY;

const SendEvent = (eventType, componentName, additionalData = {}) => {
    const payload = {
        eventType,
        componentName,
        timestamp: new Date().toISOString(),
        secret_key: SECRET_KEY,
        ...additionalData
    };

    fetch(TRACK_ENDPOINT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => console.log(`${eventType} event tracked:`, data))
    .catch(error => console.error(`Error tracking ${eventType} event:`, error));
};

export default SendEvent;
