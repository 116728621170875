import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TopButtonGroup from "../TopButtonGroup";

import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';



function Header({data,formRef}) {

  const componentName = 'Eval_Header';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);

  const handleClick = (url,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    if (url) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
  };


  return (
    
    <Container ref={ref} fluid className="home-header-section" id="home-header">
        
        <br></br>
        <br></br>
        <br></br>


        
        <Container fluid className="home-header-description" id="home-header">


          {/* <object type="image/svg+xml" data={data?.header?.logo ?? ''} ></object> */}

          <object type="image/svg+xml" data={data?.header?.logo ?? ''} style={{'width:':'75%'}}></object>

          <br></br>
          <br></br>

          {/* <TopButtonGroup
             leftButtonText={data?.header?.top_button_group?.left_button_text ?? ''}
             centerText={data?.header?.top_button_group?.center_text ?? ''}
             rightButtonText={data?.header?.top_button_group?.right_button_text ?? ''}
             leftButtonUrl={data?.header?.top_button_group?.left_button_url ?? ''}
             rightButtonUrl={data?.header?.top_button_group?.right_button_url ?? ''}
          /> */}
          
          <TopButtonGroup
             leftButtonText={data?.header?.top_button_group?.left_button_text ?? ''}
             centerText={data?.header?.top_button_group?.center_text ?? ''}
             rightButtonText={data?.header?.top_button_group?.right_button_text ?? ''}
             leftButtonUrl={formRef}
             rightButtonUrl={formRef}
          />
          
          <br></br>


          <h1 className="title">
            <b>{data?.header?.title ?? ''}</b>
          </h1>
          <h1 className='title' style={{ color: '#bd95ff' }}>
            <b>{data?.header?.subtitle ?? ''}</b>
          </h1>
          <h5 className="subscription-subtitle">
            {/* You can use Modlee to reduce your experimentation and deploy ML solutions faster in three easy steps */}
            {data?.header?.body ?? ''}
          </h5>


        </Container>

        {/* <Button variant="primarypurple" href={header_data?.url ?? ''} target="_blank" rel="noopener noreferrer">
          {header_data?.title ?? ''}
        </Button>

        <br></br>
        <br></br> */}

    </Container>
  );
}
export default Header;
