import React from 'react';
import Button from 'react-bootstrap/Button';
import '../../style.css'; // Import CSS file for custom styling if needed
import { Container, Row, Col } from "react-bootstrap";

function SolutionHeader({data,solution_description_data}) {
  return (

    <Container fluid className="home-solution-header-section" id="home-header">
        <Container fluid className="home-header-description" id="home-header">


          <div className="section_label">
            <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/logo_icon.svg'} style={{ width: '60px',height: '60px' }}> </object>
          </div>


          <h1 >
            <b>{data?.solution?.title ?? ''}</b>
          </h1>
          <h5 className="subscription-subtitle">
            {/* You can use Modlee to reduce your experimentation and deploy ML solutions faster in three easy steps */}
            {solution_description_data}
          </h5>

        </Container>

    </Container>

  );
}

export default SolutionHeader;
