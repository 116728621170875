import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import '../../style.css'; // Import CSS file for custom styling if needed

import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';


function FeaturesHeader({data,features_description_data}) {

  const componentName = 'FeaturesHeader';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);

  return (
    <Container ref={ref} fluid className="home-problem-header-section" id="home-header">
        <Container fluid className="home-header-description" id="home-header">

      <div className="section_label">
        {data?.features?.section_title ?? ''}
      </div>
      <h1 className="info-header-title"><b>{features_description_data}</b></h1>

        </Container>
    </Container>

  );
}

export default FeaturesHeader;
