import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';

const Feature = ({ title, body, button_title, button_url, svg }) => {
  
  const componentName = 'Eval_'+title;
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);

  const handleClick = (button_url,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    window.open(button_url, '_blank', 'noopener,noreferrer');
  };


  
  return (

    <Row ref={ref} className='info-row'>

        <Col md={5} className='feature-container-text' >
            <div className='feature-header-div'>

              <h2 className='feature-title' >
                <b>{title}</b>
              </h2>

              <h5 className='feature-text' >
              
                {body}
              </h5>
              <br></br>

              {/* <a className='info-bottom-link' href={button_url}  target="_blank" rel="noopener noreferrer">
                {button_title}
              </a> */}
              <a className='info-bottom-link' onClick={() => handleClick(button_url,button_title)} style={{ cursor: 'pointer' }}>
                {button_title}
              </a>
            </div>
        </Col>
        <Col md={5} className='feature-container-diagram' >
              <object type="image/svg+xml" data={process.env.PUBLIC_URL + svg} ></object>
        </Col>
    </Row>

  );
};

function Features({data}) {

  return (

    <div>
      {data?.features?.features?.map((feature, index) => (
        <Feature 
          key={index}
          title={feature?.title ?? ''}
          body={feature?.body ?? ''}
          button_title={feature?.button_title ?? ''}
          button_url={feature?.button_url ?? ''}
          svg={feature?.svg ?? ''}
        />
      ))}
    </div>

  );
}

export default Features;
