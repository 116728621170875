import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


const Step = ({ title, body, svg }) => {
  return (

      <Col md={3} className='info-container'>
       
        <div className='info-goal-div'>

            <object type="image/svg+xml" data={process.env.PUBLIC_URL + svg} style={{ width: '75%' , margin: "auto",display: "block",}}></object>

            <div className='info-div-button' >
                
                <h2 >
                  {title}
                </h2>
                <h6 >
                  {body}
                </h6>
            </div>
        </div>
      </Col>

  );
};

function How({data}) {

  return (
    <Row className='info-row'>

        {data?.how?.steps?.map((step, index) => (
          <Step 
            key={index}
            title={step?.title ?? ''}
            body={step?.body ?? ''}
            svg={step?.svg ?? ''}
          />
        ))}

    </Row>

  );
}



export default How;
