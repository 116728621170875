import Accordion from 'react-bootstrap/Accordion';
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "../../style.css";

import React, { useState, useEffect} from 'react';
// import Modal from 'react-modal';
import Modal from 'react-bootstrap/Modal';

import termsConditions from './TermsConditions';
import dataStatement from './DataStatement';



import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';
  
const Question = ({ question, answer ,index}) => {
  
  const handleAccordionClick = (question) => {
      // Send event data when an accordion item is clicked
      SendEvent('click', 'AccordionItem', { question });
  };

  return (
      <Accordion.Item eventKey={index}>
        <Accordion.Header onClick={() => handleAccordionClick(question)}>
          {question}</Accordion.Header>
        <Accordion.Body style={{ textAlign: 'left' }}>
        {answer}
        </Accordion.Body>
      </Accordion.Item>
  );
};

function NestedAccordion({data}) {

  const [showTC, setTCShow] = useState(false);
  const [showDS, seDSShow] = useState(false);

  const handleTCClose = () => setTCShow(false);
  const handleTCShow = () => setTCShow(true);
  const handleDSClose = () => seDSShow(false);
  const handleDSShow = () => seDSShow(true);


  const componentName = 'FAQ';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);

  const handleClick = (url,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    if (url) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (

    <Container ref={ref}>
      <Container>

      <div className="info-header-container">

        <h1 style={{color:'white'}}>
          <b>{data?.faq?.title ?? ''}</b>
        </h1>

        <h5 className="subscription-subtitle">
          {/* Whether it’s AI startup products, research papers, or hobby & school projects, developers all over the world use Modlee to collaborate together unlocking their ability to be more productive & build better solutions */}
          {data?.faq?.body ?? ''}
        </h5>


      </div>

      <Button 
          variant="primarypurple" 
          onClick={() => handleClick(data?.faq?.call_to_action.button_url, data?.faq?.call_to_action.button_title ?? '')}>
          {data?.faq?.call_to_action.button_title ?? ''}
      </Button>


      <br></br>
      <br></br>
      <br></br>
      <br></br>

    {/* <Accordion className='react-bootstrap-accordion'>


      <Accordion.Item eventKey="1">
        <Accordion.Header onClick={() => handleAccordionClick(data?.faq?.questions.q_1.q)}>{data?.faq?.questions.q_1.q ?? ''}</Accordion.Header>
        <Accordion.Body style={{ textAlign: 'left' }}>
        {data?.faq?.questions.q_1.a ?? ''}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>{data?.faq?.questions.q_2.q ?? ''}</Accordion.Header>
        <Accordion.Body style={{ textAlign: 'left' }}>
        {data?.faq?.questions.q_2.a ?? ''}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>{data?.faq?.questions.q_3.q ?? ''}</Accordion.Header>
        <Accordion.Body style={{ textAlign: 'left' }}>
        {data?.faq?.questions.q_3.a ?? ''}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>{data?.faq?.questions.q_4.q ?? ''}</Accordion.Header>
        <Accordion.Body style={{ textAlign: 'left' }}>
        {data?.faq?.questions.q_4.a ?? ''}
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="5">
        <Accordion.Header>More questions ...</Accordion.Header>
        <Accordion.Body>


          <Accordion className='react-bootstrap-accordion'>


            <Accordion.Item eventKey="5">
              <Accordion.Header>{data?.faq?.questions.q_5.q ?? ''}</Accordion.Header>
              <Accordion.Body style={{ textAlign: 'left' }}>
              {data?.faq?.questions.q_5.a ?? ''}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>{data?.faq?.questions.q_6.q ?? ''}</Accordion.Header>
              <Accordion.Body style={{ textAlign: 'left' }}>
              {data?.faq?.questions.q_6.a ?? ''}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>{data?.faq?.questions.q_7.q ?? ''}</Accordion.Header>
              <Accordion.Body style={{ textAlign: 'left' }}>
              {data?.faq?.questions.q_7.a ?? ''}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8">
              <Accordion.Header>{data?.faq?.questions.q_8.q ?? ''}</Accordion.Header>
              <Accordion.Body style={{ textAlign: 'left' }}>
              {data?.faq?.questions.q_8.a ?? ''}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9">
              <Accordion.Header>{data?.faq?.questions.q_9.q ?? ''}</Accordion.Header>
              <Accordion.Body style={{ textAlign: 'left' }}>
              {data?.faq?.questions.q_9.a ?? ''}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="10">
              <Accordion.Header>{data?.faq?.questions.q_10.q ?? ''}</Accordion.Header>
              <Accordion.Body style={{ textAlign: 'left' }}>
              {data?.faq?.questions.q_10.a ?? ''}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="11">
              <Accordion.Header>{data?.faq?.questions.q_11.q ?? ''}</Accordion.Header>
              <Accordion.Body style={{ textAlign: 'left' }}>
              {data?.faq?.questions.q_11.a ?? ''}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="12">
              <Accordion.Header>{data?.faq?.questions.q_12.q ?? ''}</Accordion.Header>
              <Accordion.Body style={{ textAlign: 'left' }}>
              {data?.faq?.questions.q_12.a ?? ''}
              </Accordion.Body>
            </Accordion.Item>


          </Accordion>

        </Accordion.Body>
      </Accordion.Item>



    </Accordion> */}


    <Accordion className='react-bootstrap-accordion'>

      {data?.faq?.questions?.map((question, index) => (
        <Question 
          key={index}
          question={question?.q ?? ''}
          answer={question?.a ?? ''}
          index={index}
        />
      ))}


      <Accordion.Item eventKey="500">
        <Accordion.Header>More questions ...</Accordion.Header>
        <Accordion.Body>


          <Accordion className='react-bootstrap-accordion'>


            {data?.faq?.more_questions?.map((question, index) => (
              <Question 
                key={index}
                question={question?.q ?? ''}
                answer={question?.a ?? ''}
                index={index}
              />
            ))}

          </Accordion>

        </Accordion.Body>
      </Accordion.Item>



    </Accordion>



    <br/>

        


</Container>
</Container>

  );
}

export default NestedAccordion;

// export default Faq;