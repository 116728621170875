import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

import Header from "./Header";
import CodeToggle from "./CodeToggle";
import Simplify from "./Simplify";
import Join from "./Join";


import LocalGifDisplay from "./Gif";
import Slider from "./Slider";
import InfoHeader from "./InfoHeader";
import InfoRow0 from "./InfoRow0";
import SolutionHeader from "./SolutionHeader";
import InfoRow1 from "./InfoRow1";
import InfoRow2 from "./InfoRow2";
import InfoRow3 from "./InfoRow3";
import Questions from "./Questions";
import Feature1 from "./Feature1";
import Feature2 from "./Feature2";
import Feature0 from "./Feature0";
import FeaturesHeader from "./FeaturesHeader";



import Build from "./Build";
import Faq from "./Faq";
import CustomizedPills from "./Tabbed";
import TwoColumnLayout from "./TwoColumn";

import Type from "./Type";
import Button from "react-bootstrap/Button";
import SliderHeader from "./SliderHeader";





function Home({data,header_data,problem_1_data,problem_2_data,
              problem_3_data,solution_description_data,solution_1_data,
              solution_2_data,solution_3_data,features_description_data,
              feature_1_data,feature_2_data,feature_3_data,saas_data}) {
  return (
    
    <section>



      <Header 
        data={data} 
        header_data={header_data}
      />

      <CodeToggle 
        data={data} 
      />

      <Simplify 
        data={data} 
        header_data={header_data}
      />

      <br></br>

      <InfoHeader 
        data={data} 
      />

      <InfoRow0 
        problem_1_data={problem_1_data}
        problem_2_data={problem_2_data}
        problem_3_data={problem_3_data}      
      />

      {/* ------------- */}

      <br></br>
      <br></br>

      <FeaturesHeader 
        data={data} 
        features_description_data={features_description_data}
      />

      <br></br>
      <br></br>
      <Feature2 
        data={data} 
        feature_1_data={feature_1_data}
      />
      <Feature0 
        data={data} 
        feature_2_data={feature_2_data}
      />
      <Feature1 
        data={data} 
        feature_3_data={feature_3_data}
      />

      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <SliderHeader 
        data={data}
      />

      <Slider />


      <br></br>
      <br></br>
      <br></br>
      

      <Build 
        data={data} 
        saas_data={saas_data}
      />

      <br></br>
      <br></br>
      <br></br>

      <Faq 
        data={data} 
      />


      {/* Need to ready ec2 for https so we can collect data securely */}
      <Join 
        data={data} 
      />

      <br></br>
      <br></br>
      <br></br>
      
    </section>

  );
}

export default Home;





