import React, { useState, useEffect} from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Header from "./Header";
import LocalGifDisplay from "./Gif";
import Slider from "./Slider";
import InfoHeader from "./InfoHeader";
import InfoRow0 from "./InfoRow0";
import InfoRow1 from "./InfoRow1";
import InfoRow2 from "./InfoRow2";
import InfoRow3 from "./InfoRow3";
import Questions from "./Questions";
import Feature1 from "./Feature1";
import Feature2 from "./Feature2";
import Feature0 from "./Feature0";
import FeaturesHeader from "./FeaturesHeader";

import termsConditions from './TermsConditions';
import dataStatement from './DataStatement';
import openSourceDisclaimerContent from './OpenSourceDisclaimer';
import Navbar from "../Navbar";


import Subscriptions from "./Subscriptions";
import Faq from "./Faq";
import CustomizedPills from "./Tabbed";
import TwoColumnLayout from "./TwoColumn";

import Type from "./Type";
import Button from "react-bootstrap/Button";

import { ButtonGroup, ToggleButton } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import Home from "./Home";


function HomeToggle({data}) {

    const [toggleValue, setToggleValue] = useState('developers');
    // http://localhost:3001/?toggle=developers
    // http://localhost:3001/?toggle=organizations
    // http://localhost:3001/?toggle=enterprise

    // Function to parse query parameters from the URL
    const getToggleValueFromURL = () => {
      const params = new URLSearchParams(window.location.search);
      const toggleParam = params.get('toggle');
      if (toggleParam) {
        setToggleValue(toggleParam);
      }
    };
  
    // Use useEffect to run the parsing function when the component mounts
    useEffect(() => {
      getToggleValueFromURL();
    }, []);


    const radios = [
        { name: data?.audience_1 ?? '', value: 'developers' },
        { name: data?.audience_2 ?? '', value: 'organizations' },
        { name: data?.audience_3 ?? '', value: 'enterprise' },
    ];

    return (
      <>


      {/* <div className="toggle-button-container">
        <button onClick={toggleComponents}>Toggle Components</button>
      </div> */}


      {/* <div className="toggle-button-container">
        <ButtonGroup>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`toggle-${idx}`}
              type="radio"
              variant={toggleValue === radio.value ? 'togglepurple' : 'outline-primarypurple'}
              name="options"
              value={radio.value}
              checked={toggleValue === radio.value}
              onChange={(e) => setToggleValue(e.currentTarget.value)}
              style={{fontSize:'12px'}}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div> */}

        <section>
          <Navbar data={data}/>
          {toggleValue === 'developers' && (
            <>
              <Home 
              data={data} 
              header_data={data?.header?.button?.dev ?? ''}
              problem_1_data={data?.problem?.problem_1?.dev ?? ''}
              problem_2_data={data?.problem?.problem_2?.dev ?? ''}
              problem_3_data={data?.problem?.problem_3?.dev ?? ''}
              solution_description_data={data?.solution?.description.dev ?? ''}
              solution_1_data={data?.solution?.solution_1?.dev ?? ''}
              solution_2_data={data?.solution?.solution_2?.dev ?? ''}
              solution_3_data={data?.solution?.solution_3?.dev ?? ''}
              features_description_data={data?.features?.description.dev ?? ''}
              feature_1_data={data?.features?.feature_1?.dev ?? ''}
              feature_2_data={data?.features?.feature_2?.dev ?? ''}
              feature_3_data={data?.features?.feature_3?.dev ?? ''}
              saas_data={data?.saas?.dev ?? ''}
              />
            </>
          )}
          {toggleValue === 'organizations' && (
            <>
              <Home 
              data={data} 
              header_data={data?.header?.button?.org ?? ''}
              problem_1_data={data?.problem?.problem_1?.org ?? ''}
              problem_2_data={data?.problem?.problem_2?.org ?? ''}
              problem_3_data={data?.problem?.problem_3?.org ?? ''}
              solution_description_data={data?.solution?.description.org ?? ''}
              solution_1_data={data?.solution?.solution_1?.org ?? ''}
              solution_2_data={data?.solution?.solution_2?.org ?? ''}
              solution_3_data={data?.solution?.solution_3?.org ?? ''}
              features_description_data={data?.features?.description.org ?? ''}
              feature_1_data={data?.features?.feature_1?.org ?? ''}
              feature_2_data={data?.features?.feature_2?.org ?? ''}
              feature_3_data={data?.features?.feature_3?.org ?? ''}
              saas_data={data?.saas?.org ?? ''}
              />
            </>
          )}
          {toggleValue === 'enterprise' && (
            <>
              <Home 
              data={data} 
              header_data={data?.header?.button?.ent ?? ''}
              problem_1_data={data?.problem?.problem_1?.ent ?? ''}
              problem_2_data={data?.problem?.problem_2?.ent ?? ''}
              problem_3_data={data?.problem?.problem_3?.ent ?? ''}
              solution_description_data={data?.solution?.description.ent ?? ''}
              solution_1_data={data?.solution?.solution_1?.ent ?? ''}
              solution_2_data={data?.solution?.solution_2?.ent ?? ''}
              solution_3_data={data?.solution?.solution_3?.ent ?? ''}
              features_description_data={data?.features?.description.ent ?? ''}
              feature_1_data={data?.features?.feature_1?.ent ?? ''}
              feature_2_data={data?.features?.feature_2?.ent ?? ''}
              feature_3_data={data?.features?.feature_3?.ent ?? ''}
              saas_data={data?.saas?.ent ?? ''}
              />
            </>
          )}
          {toggleValue === 'terms' && (
            <Container fluid className="home-header-section">
                <Container fluid className="home-header-description">

                    <h2>Modlee's Terms of Service</h2>
                    <p>{termsConditions}</p>

                </Container>
            </Container>
          )}
          {toggleValue === 'data_policy' && (

            <Container fluid className="home-header-section">
                <Container fluid className="home-header-description">

                    <h2>Modlee's Data Privacy Policy</h2>
                    <p>{dataStatement}</p>

                </Container>
            </Container>

          )}

          {toggleValue === 'open_source_disclaimer' && (

            <Container fluid className="home-header-section">
                <Container fluid className="home-header-description">

                    <h2>Modlee's Open Source Disclaimer</h2>
                    <p>{openSourceDisclaimerContent}</p>

                </Container>
            </Container>

          )}


        </section>
      </>
    );
  }
  
  export default HomeToggle;