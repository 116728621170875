import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ToggleText from "./ToggleText";

import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';

const Step = ({ title, body }) => {
    return (

        <Col md={3} className='build-top-container'>
        
            <div className='info-goal-div'>

                <div className='info-div-button' >
                    <h4 >
                    {title}
                    </h4>
                    <h6 >
                    {body}
                    </h6>
                </div>

            </div>
        </Col>

    );
  };

const Body = ({ text }) => {
    return (
        <div>
                <h5 className="subscription-subtitle">
                    {text}
                </h5>
                <br></br>
        </div>
    );
  };

function Value({data,formRef}) {


    const componentName = "Eval_Value";
    const onVisible = () => SendEvent('visibility', componentName);
    const ref = UseVisibility(onVisible);
  

    const handleButtonClick = (ref,buttonName) => {
        SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };
    
    const handleClick2 = (button_url,buttonName) => {
        SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
        window.open(button_url, '_blank', 'noopener,noreferrer');
      };
    

  return (
    <Container ref={ref} fluid className="subscription-container">
      <Container>

        <h1 className="info-header-title">
          <b>{data?.value?.title ?? ''}</b>
        </h1>
        <br></br>
        <div>
            {data?.value?.body?.map((element, index) => (
                <Body 
                    key={index}
                    text={element?.text ?? ''}
                />
            ))}
        </div>

        <object type="image/svg+xml" data={process.env.PUBLIC_URL + data?.value?.divider ?? ''} className="svgObjectStyle"></object>

        <Row className='info-row'>
            {data?.value?.steps?.map((step, index) => (
                <Step 
                    key={index}
                    title={step?.title ?? ''}
                    body={step?.body ?? ''}
                />
            ))}
        </Row>

        <Row className='info-row'>
            <Col md={3} className='build-top-container3'>            
                    <div className='build-div' >
                        <h1 >
                        {data?.value?.action_1?.title ?? ''}
                        </h1>
                        {/* <a className='build-bottom-link' href={data?.value?.action_1?.button_url ?? ''}  target="_blank" rel="noopener noreferrer">
                        {data?.value?.action_1?.button_title ?? ''}
                        </a> */}
                        <a className='build-bottom-link' onClick={() => handleButtonClick(formRef,data?.value?.action_1?.button_title ?? '')}>
                        {data?.value?.action_1?.button_title ?? ''}
                        </a>

                    </div>
            </Col>
            <Col md={3} className='build-top-container2'>
                <div className='info-goal-div'>

                    <ToggleText
                        data={data}
                    />

                </div>
            </Col>
        </Row>

        <br></br>
        {/* <a className='build-bottom-link' href={data?.value?.action_2?.button_url ?? ''}  target="_blank" rel="noopener noreferrer">
            <h3>{data?.value?.action_2?.button_title ?? ''}</h3>
        </a> */}

        <a className='build-bottom-link' onClick={() => handleClick2(data?.value?.action_2?.button_url ?? '',data?.value?.action_2?.button_title ?? '')}>
            {/* <FaGithub style={{ fontSize: "2.1em" }} /> */}
            <h3>{data?.value?.action_2?.button_title ?? ''}</h3>
        </a>

        

      </Container>
    </Container>
  );
}

export default Value;
