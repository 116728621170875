import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import TopButtonGroup from "./TopButtonGroup";
import { FaDiscord } from "react-icons/fa";
import InterestForm from "./Form";


import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';



function Join({data}) {


  const componentName = 'Join';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);

  const handleClick = (url,buttonName) => {
      SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
      if (url) {
          window.open(url, '_blank', 'noopener,noreferrer');
      }
  };

  return (
    
    <Container ref={ref} fluid className="home-header-section" id="home-header">
        
        
        <Container fluid className="home-header-description" id="home-header">



          <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/world_connection_2.svg'} style={{width: '100%'}}></object>


          <h1 className="title">
            <b>{data?.join?.title ?? ''}</b>
          </h1>
          <h5 className="subscription-subtitle">
            {/* You can use Modlee to reduce your experimentation and deploy ML solutions faster in three easy steps */}
            {data?.join?.body ?? ''}
          </h5>


        </Container>

        {/* <Button variant="primarypurple" href={data?.simplify?.button_url ?? ''} target="_blank" rel="noopener noreferrer">
          {data?.join?.button_text ?? ''}
        </Button> */}

        <a 
            className='build-bottom-link'
            onClick={() => handleClick(data?.join?.button_url ?? '', data?.join?.button_text ?? '')}>
            <FaDiscord style={{ fontSize: "2.1em" }} />
            <h3>{data?.join?.button_text ?? ''}</h3>
        </a>


        <br></br>
        <br></br>




        <Row className='info-row'>

            <Col md={3} className='build-top-container3'>
            



                    <div className='build-div' >
                        <h1 >
                            {data?.join?.form_title ?? ''}
                        </h1>
                        <h1 style={{ color: '#bd95ff' }}>
                            {data?.join?.form_title_2 ?? ''}
                        </h1>
                        <h6 className="subscription-subtitle">
                            {data?.join?.form_body ?? ''}
                        </h6>


                    </div>

            </Col>


            <Col md={3} className='build-top-container2'>
            

                <div className='info-goal-div'>

                    <InterestForm
                        title='test'
                    />
                    
                </div>
            </Col>


        </Row>


    </Container>
  );
}
export default Join;
