// import React, { useState, useEffect, useRef } from 'react';

// const ToggleSVG = ({ button_1_text, button_2_text, button_3_text }) => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const [containerHeight, setContainerHeight] = useState('auto');
//   const containerRef = useRef(null);

//   const buttons = [
//     { label: button_1_text, image: <object type="image/svg+xml" data={`${process.env.PUBLIC_URL}/code_example_connect.svg`} style={{ width: "100%" }}></object> },
//     { label: button_2_text, image: <object type="image/svg+xml" data={`${process.env.PUBLIC_URL}/code_example_benchmark.svg`} style={{ width: "100%" }}></object> },
//     { label: button_3_text, image: <object type="image/svg+xml" data={`${process.env.PUBLIC_URL}/code_example_progress.svg`} style={{ width: "100%" }}></object> }
//   ];

//   const handleClick = (index) => {
//     if (index !== activeIndex) {
//       setActiveIndex(index);
//     }
//   };

//   useEffect(() => {
//     const updateHeight = () => {
//       const currentActive = containerRef.current.querySelector('.enter object');
//       if (currentActive) {
//         const height = currentActive.clientHeight;
//         setContainerHeight(`${height}px`); // Set the state for container height
//       }
//     };

//     // Adding a slight delay to allow the SVG to render might be necessary
//     const timeoutId = setTimeout(updateHeight, 100); // Adjust time as needed

//     return () => clearTimeout(timeoutId);
//   }, [activeIndex]); // Re-run this effect when activeIndex changes

//   return (
//     <div>
//       <div className="top-button-group code-button-group">
//         {buttons.map((button, index) => (
//           <button
//             key={index}
//             className={`top-button-style ${index === activeIndex ? 'active' : ''}`}
//             onClick={() => handleClick(index)}
//           >
//             {button.label}
//           </button>
//         ))}
//       </div>
//       <div className="image-display" ref={containerRef} style={{ height: containerHeight }}>
//         {buttons.map((button, index) => (
//           <div
//             key={index}
//             className={`svg-container ${index === activeIndex ? 'enter' : 'hidden'}`}
//           >
//             {button.image}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ToggleSVG;

import React, { useState, useEffect, useRef } from 'react';
import SendEvent from '../SendEvent';

const ToggleSVG = ({ button_1_text, button_2_text, button_3_text }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [containerHeight, setContainerHeight] = useState('auto');
  const containerRef = useRef(null);

  const componentName = 'ToggleSVG';

  const buttons = [
    { label: button_1_text, svgPath: `${process.env.PUBLIC_URL}/code_example_connect.svg` },
    { label: button_2_text, svgPath: `${process.env.PUBLIC_URL}/code_example_benchmark.svg` },
    { label: button_3_text, svgPath: `${process.env.PUBLIC_URL}/code_example_progress.svg` }
  ];

  const handleLoad = (event) => {
    const height = event.target.clientHeight;
    setContainerHeight(`${height}px`);
  };

  const handleClick = (index,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    setActiveIndex(index);
  };

  useEffect(() => {
    const currentSVG = containerRef.current.children[activeIndex];
    if (currentSVG) {
      const height = currentSVG.clientHeight;
      if (height > 0) setContainerHeight(`${height}px`);
    }
  }, [activeIndex]);

  return (
    <div>
      <div className="top-button-group code-button-group">
        {buttons.map((button, index) => (
          <button
            key={index}
            className={`top-button-style ${index === activeIndex ? 'active' : ''}`}
            onClick={() => handleClick(index,button.label)}
          >
            {button.label}
          </button>
        ))}
      </div>
      <div className="image-display" ref={containerRef} style={{ height: containerHeight }}>
        {buttons.map((button, index) => (
          <object
            key={index}
            type="image/svg+xml"
            data={button.svgPath}
            style={{ width: "100%", display: index === activeIndex ? 'block' : 'none' }}
            onLoad={handleLoad}
          ></object>
        ))}
      </div>
    </div>
  );
};

export default ToggleSVG;
