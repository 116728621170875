
const openSourceDisclaimerContent = `

The following terms (the “Terms”) apply to you (“you” or “your”) as a user of the Code that is made available by the copyright holder and its contributors (“Copyright Holder”, “our” or “we”). “Code” shall mean any software code that is distributed as “free software” or “open-source software” or is otherwise distributed publicly in source code form under terms that permit modification and redistribution of such software. By using/continuing to use our Code, you acknowledge you have read and understand and agree to be bound by the Terms, including those additional terms and conditions and policies referenced herein and/or available by hyperlink.
Intellectual Property Rights
You may use, distribute and modify this Code under the terms of Apache License, Version 2.0, January 2004.
Disclaimers; Limitations of Liability
YOU EXPRESSLY AGREE THAT ACCESS TO AND USE OF THE CODE IS AT YOUR SOLE RISK AND IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, NEITHER COPYRIGHT HOLDER NOR ITS AFFILIATES OR SUBSIDIARIES, OR ANY OF THEIR DIRECTORS, OFFICERS, EMPLOYEES, CONTRACTORS, AGENTS, ATTORNEYS, THIRD-PARTY PROVIDERS, DISTRIBUTORS, LICENSEES, LICENSORS, SUCCESSORS OR ASSIGNS (COLLECTIVELY, THE “COPYRIGHT HOLDER PARTIES”) WARRANT THAT THE CODE WILL BE UNINTERRUPTED, SECURE, BUG-FREE OR ERROR-FREE, AND NONE OF THE COPYRIGHT HOLDER PARTIES WARRANT THAT THE CODE IS MERCHANTABLE, FIT FOR ANY PARTICULAR PURPOSE. THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE CODE IS WITH YOU. SHOULD THE CODE PROVE DEFECTIVE, YOU ASSUME THE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION. 
TO THE FULLEST EXTENT PERMITTED BY LAW, THE DISCLAIMERS OF LIABILITY CONTAINED HEREIN APPLY TO ANY AND ALL DAMAGES, LOSSES AND/OR INJURY WHATSOEVER CAUSED BY OR RELATED TO USE OF, OR INABILITY TO USE, THE CODE UNDER ANY CAUSE OR ACTION WHATSOEVER OF ANY JURISDICTION, INCLUDING, WITHOUT LIMITATION, ACTIONS FOR BREACH OF WARRANTY, BREACH OF CONTRACT AND/OR TORT (INCLUDING NEGLIGENCE). THE COPYRIGHT HOLDER PARTIES SHALL NOT BE LIABLE FOR ANY LOSS, INCLUDING, BUT NOT LIMITED TO, LOST PROFITS, REVENUES, OR FINANCIAL LOSSES OR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE AND/OR CONSEQUENTIAL DAMAGES IN ANY WAY WHATSOEVER ARISING OUT OF THE USE OF, OR INABILITY TO USE, THE CODE, OR FOR ANY DAMAGES RELATED TO THE LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS, LOSS OF USE, LOSS OF GOODWILL OR LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF THE COPYRIGHT HOLDER PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU FURTHER SPECIFICALLY ACKNOWLEDGE THAT THE COPYRIGHT HOLDER PARTIES ARE NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD THE COPYRIGHT HOLDER PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OTHER USERS OF THE CODE, AND THAT THE RISK OF THE USE OF THE CODE AND OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
IN THE EVENT THAT A COURT AND/OR ARBITRATOR(S) OF COMPETENT JURISDICTION HOLDS THAT ANY OF THE COPYRIGHT HOLDER PARTIES IS LIABLE TO YOU (FOR EXAMPLE AND WITHOUT LIMITATION, BECAUSE ANY RELEASE OR WAIVER HEREUNDER IS FOUND TO BE VOID OR OTHERWISE UNENFORCEABLE, OR BECAUSE ANY CLAIMS ARE FOUND TO BE OUTSIDE THE SCOPE OF ANY SUCH RELEASE OR WAIVER), UNDER NO CIRCUMSTANCES WILL ANY OF THE COPYRIGHT HOLDER PARTIES BE LIABLE TO YOU IN THE AGGREGATE FOR MORE THAN ONE HUNDRED DOLLARS ($100), WHETHER SUCH LIABILITY IS BASED ON BREACH OF WARRANTY, BREACH OF CONTRACT OR TORT (INCLUDING NEGLIGENCE) OR OTHERWISE. YOU AGREE THAT THE PROVISIONS IN THIS SECTION (DISCLAIMERS; LIMITATIONS OF LIABILITY) WILL SURVIVE ANY TERMINATION OF YOUR USE OF OR ACCESS TO THE CODE.
Indemnification
You agree to indemnify, defend and hold the Copyright Holder Parties harmless from and against any and all losses, claims, damages, judgments, demands, actions, proceedings, investigations (whether formal or informal), or expenses (including reasonable attorneys’ fees), or threats thereof, due to, arising out of or relating to (a) your violation of (i) any law or (ii) the rights of a third-party, or (b) your use of the Code.
In the event of such a claim, suit, or action, we will attempt to provide you notice of the claim, suit, or action at the contact information we have for your account/on file (provided, that failure to deliver such notice shall not eliminate or reduce your indemnification obligations hereunder). The Copyright Holder Parties reserve the right, at their own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with the Copyright Holder Parties in asserting any available defenses. You agree that the provisions in this Section (Indemnification) will survive any termination of your use of or access to the code.
Miscellaneous
We reserve the right, at our sole discretion, to update, change, modify, or replace any part of these Terms by posting updates and changes. It is your responsibility to check regularly for changes to these Terms. Your continued use of or access to the Code following the posting of any changes to these Terms constitutes acceptance of those changes. These Terms constitute the complete and exclusive agreement and understanding between you and us related to the Code. These Terms and all disputes arising out of or relating to the Terms shall be governed by, construed, and enforced in accordance with the laws of the State of New York in the United States, without regard to its conflict of laws principles. These disputes will be resolved exclusively in the federal and state courts in the County and State of New York, and you and we consent to personal jurisdiction in those courts.

`;

export default openSourceDisclaimerContent;