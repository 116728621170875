import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { CgGitFork } from "react-icons/cg";
import { ImBlog } from "react-icons/im";
import { FaLinkedinIn, FaDiscord, FaGithub, FaPython} from "react-icons/fa";

import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDiscord } from '@fortawesome/free-brands-svg-icons';


import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
  AiOutlineTeam,
} from "react-icons/ai";


import { CgFileDocument } from "react-icons/cg";

import SendEvent from './SendEvent'; // Assuming you have this function to send events


function NavbarEval({data,formRef,joinRef}) {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);


  const handleNavLinkClick = (url,title) => {
      // Sending an event when the Nav.Link is clicked
      SendEvent('click', 'NavLink', { title });

      // Navigate to the URL
      if (url) {
          window.open(url, '_blank', 'noopener,noreferrer');
      }
  };

  const handleButtonClick = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >


      <Container>
        
        <Navbar.Brand href="/" className="d-flex">
          {/* <img src={logo} alt="brand" style={{ maxHeight: "30px" }}/> */}
          <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/modlee_logo.svg'} style={{ height: "40px"}}></object>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav">
          
          <Nav className="ms-auto" defaultActiveKey="#home">

            <Nav.Item>
              <Nav.Link onClick={() => handleButtonClick(formRef)}>Launch Assessment</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link onClick={() => handleButtonClick(joinRef)}>Join Talent Pool</Nav.Link>
            </Nav.Item>


            <Nav.Item>
              <Nav.Link as={NavLink} to="/">Package</Nav.Link>
            </Nav.Item>



          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarEval;
