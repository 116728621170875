
import { Form, Row, Col, Button, Container, Alert } from 'react-bootstrap';
import { useState } from 'react';

const FORM_ENDPOINT = process.env.REACT_APP_INTEREST_SUBMISSION_ENDPOINT;
const SECRET_KEY = process.env.REACT_APP_BACKEND_SECRECT_KEY;

function InterestForm({title}) {
    const [formStatus, setFormStatus] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        // Create a FormData object and populate it with the form's elements
        const formData = new FormData(event.target);
        formData.append('secret_key', SECRET_KEY);

        fetch(FORM_ENDPOINT, {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            setFormStatus('success');
        })
        .catch((error) => {
            console.error('Error:', error);
            setFormStatus('error');
        });
    };

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            <Form action={FORM_ENDPOINT} onSubmit={handleSubmit} method="POST" style={{ width: '100%', maxWidth: '500px' }}>
                <input type="hidden" name="form_id" value={title} />

                <Row className="mb-3">
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="First name"
                            name="firstName"
                            required
                            style={{ border: '1px solid grey', backgroundColor: 'rgba(255, 255, 255, 0.75)' }}
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="Last name"
                            name="lastName"
                            required
                            style={{ border: '1px solid grey', backgroundColor: 'rgba(255, 255, 255, 0.75)' }}
                        />
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            required
                            style={{ border: '1px solid grey', backgroundColor: 'rgba(255, 255, 255, 0.75)' }}
                        />
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Control
                            as="textarea"
                            placeholder="Start the conversation"
                            name="message"
                            style={{ height: '100px', border: '1px solid grey', backgroundColor: 'rgba(255, 255, 255, 0.75)' }}
                        />
                    </Col>
                </Row>

                <div className="d-flex justify-content-center">
                    <Button variant="primarypurple" type="submit">
                        Submit
                    </Button>
                </div>

                <br></br>
                {formStatus === 'success' && <Alert variant="success">Submission successful!</Alert>}
                {formStatus === 'error' && <Alert variant="danger">Submission failed!</Alert>}

            </Form>
        </Container>
    );
}

export default InterestForm;
