import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { CgGitFork } from "react-icons/cg";
import { ImBlog } from "react-icons/im";
import { FaLinkedinIn, FaDiscord, FaGithub, FaPython} from "react-icons/fa";

import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDiscord } from '@fortawesome/free-brands-svg-icons';


import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
  AiOutlineTeam,
} from "react-icons/ai";


import { CgFileDocument } from "react-icons/cg";

import SendEvent from './SendEvent'; // Assuming you have this function to send events


function NavBar({data}) {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);


  const handleNavLinkClick = (url,title) => {
      // Sending an event when the Nav.Link is clicked
      SendEvent('click', 'NavLink', { title });

      // Navigate to the URL
      if (url) {
          window.open(url, '_blank', 'noopener,noreferrer');
      }
  };

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >


      <Container>
        
        <Navbar.Brand href="/" className="d-flex">
          {/* <img src={logo} alt="brand" style={{ maxHeight: "30px" }}/> */}
          <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/modlee_logo.svg'} style={{ height: "40px"}}></object>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav">
          
          <Nav className="ms-auto" defaultActiveKey="#home">
{/*             

            <Nav.Item className="fork-btn">
              <Button
                // href="https://github.com/modlee-ai/modlee_pypi"
                href={data?.github_url ?? ''}
                target="_blank"
                className="fork-btn-inner"
              >
                <FaGithub style={{ fontSize: "1.1em" }} />{" "}
                <CgGitFork style={{ fontSize: "1.2em" }} />{" "}
                <AiFillStar style={{ fontSize: "1.1em" }} />{" "}
              </Button>
            </Nav.Item>


            <Nav.Item>
              <Nav.Link
                href={data?.pypi_url ?? ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaPython />
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                href={data?.discord_url ?? ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaDiscord />
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                href={data?.linkedin_url ?? ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
              <Nav.Link
                  href="https://dashboard.modlee.ai"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Sign In/Up
              </Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
              <Nav.Link
                  href="https://www.modlee.ai"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Sign Up
              </Nav.Link>
            </Nav.Item> */}


            {/* <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={(handleSignup)}>
                Sign In/Up
              </Nav.Link>
            </Nav.Item> */}

            <Nav.Item>
              {/* <Nav.Link
                href={data?.sign_in_url ?? ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                Log In
              </Nav.Link> */}

              <Nav.Link
                  onClick={() => handleNavLinkClick(data?.sign_in_url ?? '', 'Log In')}
                  style={{ cursor: 'pointer' }} // Add cursor pointer to indicate it's clickable
              >
                  Log In
              </Nav.Link>


            </Nav.Item>

            {/* <Nav.Item>
              <Nav.Link
                href={data?.sign_up_url ?? ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                Sign Up
              </Nav.Link>
            </Nav.Item> */}

        <Nav.Item>
          {/* <Nav.Link
            href={data?.sign_up_url ?? ''}
            target="_blank"
            rel="noopener noreferrer"
            className="button-like"
          >
            Sign Up
          </Nav.Link> */}

          <Nav.Link
              onClick={() => handleNavLinkClick(data?.sign_up_url ?? '', 'Sign Up')}
              style={{ cursor: 'pointer' }} // Add cursor pointer to indicate it's clickable
          >
            Sign Up
          </Nav.Link>

        </Nav.Item>



            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineTeam style={{ marginBottom: "2px" }} /> About
              </Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/project"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                Projects
              </Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/resume"
                onClick={() => updateExpanded(false)}
              >
                <CgFileDocument style={{ marginBottom: "2px" }} /> Resume
              </Nav.Link>
            </Nav.Item> */}

            <Nav.Item>
              <Nav.Link as={NavLink} to="/eval">Eval</Nav.Link>
            </Nav.Item>



          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
