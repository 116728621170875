import React, { useState, useEffect } from 'react';

import SendEvent from '../SendEvent';


const ToggleText = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [texts, setTexts] = useState([]);

  useEffect(() => {
    if (data && data.value && data.value.testimonial && data.value.testimonial.people) {
      const people = data.value.testimonial.people;
      const textsArray = Object.keys(people).map(key => ({
        name: people[key].name,
        body: people[key].body
      }));
      setTexts(textsArray);
    }
  }, [data]);

  const componentName = "Eval_Toggle Text";

  const handleClick = (index,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    setActiveIndex(index);
  };

  return (
    <div>
        <br></br>

        <div className='info-div-button-top' >

                <h6>
                    {data?.value?.testimonial?.setup ?? ''}
                </h6>
        </div>
        <br></br>
        <div className="top-button-group build-button-group">

            {texts.map((text, index) => (
            <button
                key={index}
                className={`top-button-style ${index === activeIndex ? 'active' : ''}`}
                onClick={() => handleClick(index,text.name)}
            >
                {text.name}
            </button>
            ))}

        </div>
        <br></br>
        <br></br>
        <div className='info-div-button-top' >

            <div className="text-display">
                <div>
                <div className="text-container">
                    {/* <h4>{texts[activeIndex].body ?? ''}</h4> */}
                    <h4>{texts.length > 0 ? texts[activeIndex].body : ''}</h4>
                </div>
                </div>
            </div>


        </div>

      <br></br>
      <br></br>
    </div>
  );
};

export default ToggleText;
