import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";

import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';

function Simplify({data,formRef}) {
  
    
  const componentName = 'Eval_Simplify';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);


  const handleButtonClick = (ref,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  
  return (
    
    <Container ref={ref} fluid className="home-header-section" id="home-header">
        
        
        <Container fluid className="home-header-description" id="home-header">


          <object type="image/svg+xml" data={process.env.PUBLIC_URL + data?.simplify?.svg ?? ''} style={{width: '100%'}}></object>


          <h1 className="title">
            <b>{data?.simplify?.title ?? ''}</b>
          </h1>
          <h5 className="subscription-subtitle">
            {/* You can use Modlee to reduce your experimentation and deploy ML solutions faster in three easy steps */}
            {data?.simplify?.body ?? ''}
          </h5>
          <br></br>

          <h3 style={{ color: '#bd95ff' }}>
            <b>{data?.simplify?.subtitle ?? ''}</b>
          </h3>


        </Container>

        {/* <Button variant="primarypurple" href={data?.simplify?.button_url ?? ''} target="_blank" rel="noopener noreferrer">
          {data?.simplify?.button_text ?? ''}
        </Button> */}
        <Button variant="primarypurple" onClick={() => handleButtonClick(formRef,data?.simplify?.button_text ?? '')}>
          {data?.simplify?.button_text ?? ''}
        </Button>

        <br></br>
        <br></br>

    </Container>
  );
}
export default Simplify;
