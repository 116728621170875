import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// import { FontAwesomeIcon } from './react-fa';
// import { faBullseyeArrow } from '@fortawesome/free-solid-svg-icons';

// import { FaBullseye } from "react-icons/fa";

import { GiBullseye } from "react-icons/gi";
import { FaConnectdevelop } from "react-icons/fa";
import { GiParkBench } from "react-icons/gi";
import { SiWpexplorer } from "react-icons/si";


import PlaySVGs from "./PlaySVGs";

const svgFilePaths = [
  '/documentation3.svg',
  '/documentation3.svg',
  '/documentation3.svg',
  // Add more SVG file paths as needed
];


import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';

function Feature1({data,feature_3_data}) {

  const componentName = 'Feature1';
  const onVisible = () => SendEvent('visibility', componentName);
  const ref = UseVisibility(onVisible);

  const handleClick = (url,buttonName) => {
    SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
    if (url) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Row rel={ref} className='info-row' id='feature1'>

        <Col md={5} className='feature-container-text'>
            <div className='feature-header-div' >

            <h2 className='feature-title' >
              <b>{data?.features?.feature_3?.title ?? ''}</b>
            </h2>

            <h5 className='feature-text' >
            
              {feature_3_data?.body ?? ''}
            </h5>
            <br></br>

            {/* <a 
              className='info-bottom-link'
              href={data?.features?.feature_3?.button_url ?? ''}  target="_blank" rel="noopener noreferrer">
              {data?.features?.feature_3?.button_title ?? ''}
            </a> */}

            <a 
              className='info-bottom-link'
              onClick={() => handleClick(data?.features?.feature_3?.button_url ?? '', data?.features?.feature_3?.button_title ?? '')}>
              {data?.features?.feature_3?.button_title ?? ''}
            </a>

            
            </div>
        </Col>


      <Col md={5} className='feature-container-diagram'>
        {/* <div className='feature-graphic-div'> */}

          <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/doc.svg'} ></object>


        {/* </div> */}

      </Col>

    </Row>

  );
}



export default Feature1;
// export InfoRow2;
