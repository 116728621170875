import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SubscriptionCard from "./SubscriptionCard";
import ToggleText from "./ToggleText";
import { FaLinkedinIn, FaDiscord, FaGithub, FaPython} from "react-icons/fa";


import UseVisibility from '../UseVisibility';
import SendEvent from '../SendEvent';

function Build({data}) {
  
    const componentName = 'Build';
    const onVisible = () => SendEvent('visibility', componentName);
    const ref = UseVisibility(onVisible);
  
    const handleClick = (url,buttonName) => {
        SendEvent('click', componentName, { buttonName: buttonName, action: 'Submitted' });
        if (url) {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };


  return (
    <Container ref={ref} fluid className="subscription-container">
      <Container>
        {/* <div className="section_label" id='sign-up'>
          {data?.saas?.section_label ?? ''}
        </div> */}

        <h1 className="info-header-title">
          {/* Take flight with GitHub Copilot. */}
          <b>{data?.build?.title ?? ''}</b>
        </h1>
        <br></br>
        <h5 className="subscription-subtitle">
          {/* Whether it’s AI startup products, research papers, or hobby & school projects, developers all over the world use Modlee to collaborate together unlocking their ability to be more productive & build better solutions */}
          {data?.build?.description_1 ?? ''}
        </h5>
        <br></br>
        <h5 className="subscription-subtitle">
          {/* Whether it’s AI startup products, research papers, or hobby & school projects, developers all over the world use Modlee to collaborate together unlocking their ability to be more productive & build better solutions */}
          {data?.build?.description_2 ?? ''}
        </h5>

        {/* <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/divider.svg'} style={{ width: '50%' ,height: '50%' , margin: "auto",display: "block"}}></object> */}
        <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/divider.svg'} className="svgObjectStyle"></object>


        <Row className='info-row'>

            <Col md={3} className='build-top-container'>
            

                <div className='info-goal-div'>

                    {/* <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/connect_icon.svg'} style={{ height: '50%' , margin: "auto",display: "block",}}></object> */}

                    <div className='info-div-button' >
                        {/* {data?.build?.preserve?.icon_svg ?? ''} */}
                        <h4 >
                        {data?.build?.preserve?.title ?? ''}
                        </h4>
                        <h6 >
                        {data?.build?.preserve?.body ?? ''}
                        </h6>
                    </div>

                </div>
            </Col>


            <Col md={3} className='build-top-container'>
            

                <div className='info-goal-div'>

                    {/* <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/benchmark_icon.svg'} style={{ height: '50%' , margin: "auto",display: "block",}}></object> */}

                    <div className='info-div-button' >
                        {/* {data?.build?.preserve?.icon_svg ?? ''} */}
                        <h4 >
                        {data?.build?.protect?.title ?? ''}
                        </h4>
                        <h6 >
                        {data?.build?.protect?.body ?? ''}
                        </h6>
                    </div>
                    
                </div>
            </Col>


            <Col md={3} className='build-top-container'>
            

                <div className='info-goal-div'>

                    {/* <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/progress_icon.svg'} style={{ height: '50%' , margin: "auto",display: "block",}}></object> */}

                    <div className='info-div-button' >
                        {/* {data?.build?.preserve?.icon_svg ?? ''} */}
                        <h4 >
                        {data?.build?.progress?.title ?? ''}
                        </h4>
                        <h6 >
                        {data?.build?.progress?.body ?? ''}
                        </h6>
                    </div>

                </div>
            </Col>

        </Row>



        <Row className='info-row'>

            <Col md={3} className='build-top-container3'>
            

                {/* <div className='info-goal-div'> */}

                    {/* <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/connect_icon.svg'} style={{ height: '50%' , margin: "auto",display: "block",}}></object> */}

                    <div className='build-div' >
                        {/* {data?.build?.preserve?.icon_svg ?? ''} */}
                        <h1 >
                        {data?.build?.call_to_action_1?.title ?? ''}
                        </h1>
                        {/* <h6 >
                        {data?.build?.preserve?.body ?? ''}
                        </h6> */}

                        <a 
                        className='build-bottom-link'
                        onClick={() => handleClick(data?.build?.call_to_action_1?.button_url ?? '', data?.build?.call_to_action_1?.button_title ?? '')}>
                        {data?.build?.call_to_action_1?.button_title ?? ''}
                        </a>


                    </div>

                {/* </div> */}
            </Col>


            <Col md={3} className='build-top-container2'>
            

                <div className='info-goal-div'>

                    {/* <object type="image/svg+xml" data={process.env.PUBLIC_URL + '/benchmark_icon.svg'} style={{ height: '50%' , margin: "auto",display: "block",}}></object> */}

                    <ToggleText
                        data={data}
                    />
                    
                </div>
            </Col>


        </Row>

        <br></br>
        <a 
            className='build-bottom-link'
            onClick={() => handleClick(data?.build?.call_to_action_2?.button_url ?? '', data?.build?.call_to_action_2?.button_title ?? '')}>
            <FaGithub style={{ fontSize: "2.1em" }} />
            <h3>{data?.build?.call_to_action_2?.button_title ?? ''}</h3>
        </a>


      </Container>
    </Container>
  );
}

export default Build;
